<script setup lang="ts">
import { computed, shallowRef } from 'vue';
import { navigationItems, navigationItemsOverride } from './api';
import { NavigationItem } from './api.types';
import { GroupedItems, ROOT } from './navigation-items.types';
import NavigationGroup from './navigation-group.vue';

function constructGroups(items: NavigationItem[]) {
  const groups = new GroupedItems();

  for (const item of items) {
    const groupKey = item.parentKey || ROOT;
    const group = groups.get(groupKey);

    if (group) group.push(item);
    else groups.set(groupKey, [item]);
  }

  console.debug('core-navigation: groups', groups);
  return groups;
}

const groups = shallowRef<GroupedItems>(new GroupedItems());
navigationItems.subscribe((items) => {
  console.debug('core-navigation: items changed', items);
  groups.value = constructGroups(items);
});

const overrideGroups = shallowRef<GroupedItems>(new GroupedItems());
navigationItemsOverride.subscribe((items) => {
  console.debug('core-navigation: override items changed', items);
  overrideGroups.value = constructGroups(items);
});

const finalGroups = computed(() => {
  if (overrideGroups.value.size) return overrideGroups.value;

  return groups.value;
});
</script>

<template>
  <ul class="navigation-items">
    <NavigationGroup :groups="finalGroups" />
  </ul>
</template>

<style scoped>
.navigation-items {
  min-width: 200px;
  padding: 14px;
  display: flex;
  flex-direction: column;
}
</style>
