import { ref } from 'vue';
import { NavigationItem } from './api.types';
import { routeChangedEvent } from '@mfl/framework';

// TODO: rename file - none of these are types

export const ROOT = 'root';
export class GroupedItems extends Map<string, NavigationItem[]> {}

export const currentRoute = ref(location.pathname);
routeChangedEvent.subscribe(() => {
  currentRoute.value = location.pathname;
});
