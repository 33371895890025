import { Priority } from '@mfl/framework';
import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import { navigationComponents } from './api';
import mfe from './core-navigation-mfe.vue';
import NavigationItems from './navigation-items.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'core-navigation',
  mainComponent: mfe,
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);

    // Instead of hard coding the items into the main component, use the dynamic components mechanism
    navigationComponents.register(
      {
        component: NavigationItems,
        key: 'MAIN_NAV_ITEMS',
      },
      {
        priority: Priority.Low - 5000,
      }
    );
  },
});

export { bootstrap, mount, unmount };

// public API
export * from './api.js';
