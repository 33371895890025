<script setup lang="ts">
import { computed } from 'vue';

import { navigateTo } from '@mfl/framework';

import { currentRoute, GroupedItems, ROOT } from './navigation-items.types';
import { NavigationItem } from './api.types';

const props = defineProps<{
  myKey?: string;
  groups: GroupedItems;
  level?: number;
}>();

const items = computed(() => {
  const key = props.myKey || ROOT;
  return props.groups.get(key);
});

type ItemState = {
  active: boolean;
};

const state = computed(() => {
  const s = new Map<string, ItemState>();
  items.value?.forEach((item) => {
    const active = item.route ? currentRoute.value.endsWith(item.route) : false;
    console.info(
      'core-navigation:',
      item.route,
      'isActive',
      active,
      ' - ',
      currentRoute.value
    );
    s.set(item.key, {
      active,
    });
  });

  return s;
});

const level = computed(() => {
  return props.level || 0;
});

async function onItemClicked(event: MouseEvent, item: NavigationItem) {
  event.preventDefault();

  // Call the items' handler method
  const shouldNavigate = item.handler ? await item.handler(item) : true;

  if (!shouldNavigate) return;

  const route = item.route;

  // TODO: Choose the first item if exist

  if (!route) {
    // Developers should return false from the handler
    console.warn(`No route defined for navigation item "${item.key}"`);
    return;
  }

  // Execute SPA navigation
  navigateTo(route);
}
</script>

<template>
  <li
    v-for="item in items"
    :key="item.key"
    :aid="item.key"
    :class="{
      'navigation-item': true,
      'navigation-item-active': state.get(item.key)?.active,
    }"
  >
    <a :href="item.route" @click="onItemClicked($event, item)">
      <span
        v-if="item.fontAwesomeIcon"
        :class="item.fontAwesomeIcon"
        :style="{
          'font-size': `${item.fontAwesomeIconSize || 20}px`,
          'margin-left': `${level * 15}px`,
        }"
      />
      <img
        v-if="item.iconUrl"
        :src="item.iconUrl"
        :style="{ 'margin-left': `${level * 15}px` }"
      />
      {{ item.text }}
    </a>
    <NavigationGroup :groups :myKey="item.key" :level="level + 1" />
  </li>
</template>

<style scoped lang="scss">
.navigation-item {
  > a {
    height: 36px;
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 8px;
    // TODO: take from CC
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
      width: 21px;
      height: 20px;
      margin-right: 8px;
    }

    > span {
      width: 21px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.navigation-item-active {
  > a {
    border-radius: 50px;
    background: rgb(var(--color-primary-300));
    // TODO: take from CC
    font-weight: 700;
  }
}
</style>
