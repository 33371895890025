<script setup lang="ts">
import { shallowRef } from 'vue';

// import strings from './core-navigation.strings';
import { NavigationComponent } from './api.types';
import { navigationComponents } from './api';

const reactiveNavComponents = shallowRef<NavigationComponent[]>([]);
navigationComponents.subscribe((components) => {
  console.debug('core-navigation: components changed', components);
  reactiveNavComponents.value = [...components];
});
</script>

<template>
  <!-- DYNAMIC COMPONENTS -->
  <div class="core-navigation-container">
    <component
      :is="component.component"
      v-for="component in reactiveNavComponents"
      :key="component.key"
      v-bind="component.props || {}"
      v-on="component.events || {}"
    >
    </component>
  </div>
</template>

<style scoped>
.core-navigation-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(var(--color-gray-100));
}
</style>
